import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useVisitPageMutation } from 'generated/graphql';
import { actions, selectors } from 'lib/redux/session';

const { initializeSession } = actions;
const { getSession } = selectors;

interface Props {
  children: React.ReactNode;
}

const RouteChangeHandler = ({ children }: Props) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const session = useSelector(getSession);
  const [visitPage] = useVisitPageMutation();

  useEffect(() => {
    dispatch(initializeSession());
  }, [dispatch]);

  useEffect(() => {
    const routeChangeComplete = async (url: string) => {
      try {
        await visitPage({ variables: { input: { path: url, sessionId: session as string } } });
        // eslint-disable-next-line no-empty
      } catch (_err) {}
    };

    router.events.on('routeChangeComplete', routeChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', routeChangeComplete);
    };
  }, [visitPage, session, router.events]);

  useEffect(() => {
    if (session) {
      visitPage({
        variables: { input: { path: window.location.pathname, sessionId: session } },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
      }).catch(() => {});
    }
  }, [session, visitPage]);

  return <>{children}</>;
};

export default memo(RouteChangeHandler);
