import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { IState } from '../types';

export const actionTypes = {
  INITIALIZE_SESSION: 'INITIALIZE_SESSION',
};

type ISession = string | undefined;

type ISessionState = {
  currentSession: ISession;
};

const initialState: ISessionState = {
  currentSession: undefined,
};

/* Action creators */

export const initializeSession: () => AnyAction = () => ({
  type: actionTypes.INITIALIZE_SESSION,
});

/* Selectors */

export const getSession: (state: IState) => ISession = (state) => state.session.currentSession;

/* Reducer */

const reducer: (state: ISessionState, action: AnyAction) => ISessionState = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload.form };
    }

    case actionTypes.INITIALIZE_SESSION: {
      const { currentSession } = state;
      return { ...state, currentSession: currentSession || uuidv4() };
    }

    default:
      return state;
  }
};

export default reducer;
