import { useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client';
import { NormalizedCacheObject } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/nextjs';
import merge from 'deepmerge';
import { BackendErrors, errorMessages } from './errors';

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

const defaultApiUrl =
  typeof window === 'undefined'
    ? process.env.NEXT_PUBLIC_PROXY_URL
    : process.env.NEXT_PUBLIC_PROXY_PATH;

export interface IApolloClientOptions {
  headers?: any;
  apiUrl?: string;
  cacheOpts?: any;
}

const createApolloClient = (opts: IApolloClientOptions = {}, intl?: IntlShape) => {
  const { headers, apiUrl, cacheOpts } = opts;

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        Sentry.addBreadcrumb({ level: 'error', data: error, message: error.message });

        let errorCode = error?.extensions?.errorCode as BackendErrors;
        if (
          error?.extensions?.errorCode === 'ValidationError' &&
          error.extensions.field === 'phone_number'
        ) {
          errorCode = 'ValidationError_phonenumber' as BackendErrors;
        }

        const translatedError = errorMessages[errorCode];

        error.message = intl
          ? translatedError
            ? intl.formatMessage(translatedError)
            : intl.formatMessage({
                id: 'errors.UnhandledError',
                defaultMessage: 'Une erreur est survenue',
              })
          : error.message;
      });
    }
  });

  const httpLink = new HttpLink({
    uri: apiUrl || defaultApiUrl,
    credentials: 'same-origin',
    fetch,
    headers,
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([errorLink, httpLink]),
    cache: new InMemoryCache(cacheOpts),
  });
};

export const initializeApollo = (
  initialState: NormalizedCacheObject | null = null,
  opts: IApolloClientOptions = {},
  intl?: IntlShape
): ApolloClient<NormalizedCacheObject> => {
  // eslint-disable-next-line no-underscore-dangle
  const _apolloClient = apolloClient ?? createApolloClient(opts, intl);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache);

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};

export const useApollo: (
  initialState: NormalizedCacheObject | null,
  opts?: IApolloClientOptions
) => ApolloClient<NormalizedCacheObject> = (initialState = null, opts = {}) => {
  const intl = useIntl();

  return useMemo(() => initializeApollo(initialState, opts, intl), [initialState, opts, intl]);
};
