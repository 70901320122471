import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';
import { IState } from 'lib/redux/types';

interface RoutingState extends IState {
  redirectTo?: string;
}

const initialState: RoutingState = {};

const reducer: (state: RoutingState, action: AnyAction) => RoutingState = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reducer;
