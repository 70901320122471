import * as Sentry from '@sentry/nextjs';
import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';
import { call, delay, put, race, select, take } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { IState } from '../types';

export const actionTypes = {
  ADD_NOTIFICATIONS: 'ADD_NOTIFICATIONS',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
  CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',
  SHOW_NEXT_NOTIFICATIONS: 'SHOW_NEXT_NOTIFICATIONS',
};

type NotificationSeverity = 'error' | 'success';

export interface INotification {
  severity: NotificationSeverity;
  message: string;
  id?: string;
}

interface INotificationState {
  queue: INotification[];
  currentNotifications: INotification[];
}

const initialState = {
  queue: [],
  currentNotifications: [],
};

/* ACTION CREATORS */

export const addNotifications: (notifications: INotification[]) => AnyAction = (notifications) => ({
  type: actionTypes.ADD_NOTIFICATIONS,
  notifications,
});

export const removeNotification: (id: string) => AnyAction = (id) => ({
  type: actionTypes.REMOVE_NOTIFICATION,
  id,
});

export const clearNotifications: () => AnyAction = () => ({
  type: actionTypes.CLEAR_NOTIFICATIONS,
});

export const showNextNotifications: () => AnyAction = () => ({
  type: actionTypes.SHOW_NEXT_NOTIFICATIONS,
});

/* Selectors */

export const hasNotificationsQueued: (state: IState) => boolean = (state) =>
  !!state.notifications.queue.length;
export const getCurrentNotifications: (state: IState) => INotification[] = (state) =>
  state.notifications.currentNotifications;

/* Reducer */

const reducer: (state: INotificationState, action: AnyAction) => IState = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload.notifications };
    }

    case actionTypes.ADD_NOTIFICATIONS: {
      action.notifications.map((notification: INotification) => {
        if (notification.severity === 'error') {
          Sentry.addBreadcrumb({
            level: 'error',
            message: notification.message,
            data: notification,
          });
        }
      });

      return {
        ...state,
        queue: action.notifications.map((notification: INotification) => ({
          ...notification,
          id: uuidv4(),
        })),
      };
    }

    case actionTypes.REMOVE_NOTIFICATION:
      return {
        ...state,
        currentNotifications: state.currentNotifications.filter(
          (notification) => notification.id !== action.id
        ),
      };

    case actionTypes.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        currentNotifications: [],
      };

    case actionTypes.SHOW_NEXT_NOTIFICATIONS:
      return {
        ...state,
        queue: [],
        currentNotifications: state.queue,
      };

    default:
      return state;
  }
};

/* Sagas */

export function* showNotification(): Generator<any, any, any> {
  yield put(showNextNotifications());
  yield delay(10000);
  yield put(clearNotifications());
}

export function* rootSaga(): Generator<any, any, any> {
  while (true) {
    const hasMoreNotificationsQueued = yield select(hasNotificationsQueued);

    if (hasMoreNotificationsQueued) {
      yield race({
        show: call(showNotification),
        new: take(actionTypes.ADD_NOTIFICATIONS),
        remove: take(actionTypes.REMOVE_NOTIFICATION),
      });
    } else {
      yield delay(200);
    }
  }
}

export default reducer;
