import { defineMessages } from 'react-intl';

export type BackendErrors =
  | 'AcceptBookingStageError'
  | 'ArchivedRoomError'
  | 'ArgumentError'
  | 'BannedError'
  | 'BookingLockedError'
  | 'CancelBookingStageError'
  | 'ContractGenerationError'
  | 'DepositAlreadyPaidError'
  | 'EmailAlreadyConfirmedError'
  | 'EmailAlreadyTakenError'
  | 'GuestAlreadyHouseSharingError'
  | 'GuestOnlyError'
  | 'HostHasNoRoomsError'
  | 'HostOnlyError'
  | 'InvalidCredentialsError'
  | 'InvalidEmailFormatError'
  | 'InvalidHoldEntryError'
  | 'InvalidInvoiceError'
  | 'InvalidPaymentError'
  | 'InvalidPhoneNumberFormatError'
  | 'InvalidTokenError'
  | 'InvalidTransferError'
  | 'NoPaymentAccountError'
  | 'NotFoundError'
  | 'PasswordAlreadyChosenError'
  | 'PasswordAlreadySetError'
  | 'PaymentError'
  | 'RejectBookingStageError'
  | 'RejectedUserError'
  | 'RemoteServiceError'
  | 'RoomBookedError'
  | 'RoomNotAvailableError'
  | 'UnauthenticatedError'
  | 'UnauthorizedError'
  | 'UnhandledError'
  | 'UserHasNoNewEmailError'
  | 'ValidationError';

export interface BackendError extends Error {
  graphQLErrors: {
    message: string;
    locations?: { column: number; line: number }[];
    path?: string[];
    error: string;
    extensions:
      | {
          errorCode: Exclude<
            BackendErrors,
            'UnauthorizedError' | 'NotFoundError' | 'ValidationError'
          >;
        }
      | {
          errorCode: 'UnauthorizedError';
          reason: string;
        }
      | {
          errorCode: 'NotFoundError';
          fields: string[];
          type: string; // User, Booking, Room
        }
      | {
          errorCode: 'ValidationError';
          field: string;
          reasons: string[];
        };
  }[];
}

export const isBackendError = (error: any | BackendError): error is BackendError =>
  'graphQLErrors' in error;

export const errorMessages = defineMessages({
  AcceptBookingStageError: {
    id: 'errors.AcceptBookingStageError',
    defaultMessage: 'Vous ne pouvez pas accepter cette réservation à ce stade',
  },
  ArchivedRoomError: { id: 'errors.ArchivedRoomError', defaultMessage: 'La chambre est archivée' },
  ArgumentError: {
    id: 'errors.ArgumentError',
    defaultMessage: 'Vous avez passé une valeur incorrecte',
  },
  BannedError: { id: 'errors.BannedError', defaultMessage: 'Votre compte a été banni' },
  BookingLockedError: {
    id: 'errors.BookingLockedError',
    defaultMessage:
      "La chambre est actuellement bloquée pour le paiement d'un autre cohabitant. Réessaye plus tard.",
  },
  CancelBookingStageError: {
    id: 'errors.CancelBookingStageError',
    defaultMessage: 'Vous ne pouvez pas annuler cette réservation à ce stade',
  },
  ContractGenerationError: {
    id: 'errors.ContractGenerationError',
    defaultMessage: 'La génération du document a échoué',
  },
  DepositAlreadyPaidError: {
    id: 'errors.DepositAlreadyPaidError',
    defaultMessage: 'La caution a déjà été payée',
  },
  EmailAlreadyConfirmedError: {
    id: 'errors.EmailAlreadyConfirmedError',
    defaultMessage: "L'adresse email est déjà confirmée",
  },
  EmailAlreadyTakenError: {
    id: 'errors.EmailAlreadyTakenError',
    defaultMessage: "L'adresse email est déjà utilisée",
  },
  GuestAlreadyHouseSharingError: {
    id: 'errors.GuestAlreadyHouseSharingError',
    defaultMessage: 'Le cohabitant a une autre cohabitation en cours à ce moment',
  },
  GuestOnlyError: {
    id: 'errors.GuestOnlyError',
    defaultMessage: 'Cette action est réservée aux cohabitants',
  },
  HostHasNoRoomsError: {
    id: 'errors.HostHasNoRoomsError',
    defaultMessage: "Vous n'avez pas de chambre",
  },
  HostOnlyError: {
    id: 'errors.HostOnlyError',
    defaultMessage: 'Cette action est réservée aux hôtes',
  },
  InvalidCredentialsError: {
    id: 'errors.InvalidCredentialsError',
    defaultMessage: 'Adresse email et/ou mot de passe incorrects',
  },
  InvalidEmailFormatError: {
    id: 'errors.InvalidEmailFormatError',
    defaultMessage: "L'adresse email est incorrecte",
  },
  InvalidHoldEntryError: {
    id: 'errors.InvalidHoldEntryError',
    defaultMessage: 'Ne nous trouvons pas le montant correspondant à cette entrée',
  },
  InvalidInvoiceError: {
    id: 'errors.InvalidInvoiceError',
    defaultMessage: 'La facture est incorrecte',
  },
  InvalidPaymentError: {
    id: 'errors.InvalidPaymentError',
    defaultMessage: "Le paiement n'est pas valide",
  },
  InvalidPhoneNumberFormatError: {
    id: 'errors.InvalidPhoneNumberFormatError',
    defaultMessage: 'Le numéro de téléphone est incorrect',
  },
  InvalidTokenError: { id: 'errors.InvalidTokenError', defaultMessage: 'Le code est invalide' },
  InvalidTransferError: {
    id: 'errors.InvalidTransferError',
    defaultMessage: 'Le transfert a échoué',
  },
  NoPaymentAccountError: {
    id: 'errors.NoPaymentAccountError',
    defaultMessage: "L'hôte n'a pas de compte de paiement",
  },
  NotFoundError: { id: 'errors.NotFoundError', defaultMessage: 'La page demandée est introuvable' },
  PasswordAlreadyChosenError: {
    id: 'errors.PasswordAlreadyChosenError',
    defaultMessage: 'Le mot de passe a déjà été choisi',
  },
  PasswordAlreadySetError: {
    id: 'errors.PasswordAlreadySetError',
    defaultMessage: 'Le mot de passe a déjà été défini',
  },
  PaymentError: {
    id: 'errors.PaymentError',
    defaultMessage: 'Une erreur est survenue lors du paiement',
  },
  RejectBookingStageError: {
    id: 'errors.RejectBookingStageError',
    defaultMessage: 'Vous ne pouvez rejeter cette réservation à ce stade',
  },
  RejectedUserError: {
    id: 'errors.RejectedUserError',
    defaultMessage: "L'utilisateur ne remplit pas les critères pour rejoindre la communauté",
  },
  RemoteServiceError: {
    id: 'errors.RemoteServiceError',
    defaultMessage: 'Une erreur causée par un service tier est survenue',
  },
  RoomBookedError: {
    id: 'errors.RoomBookedError',
    defaultMessage: 'La chambre est déjà réservée à cette période',
  },
  RoomNotAvailableError: {
    id: 'errors.RoomNotAvailableError',
    defaultMessage: "La chambre n'est pas disponible à cette période",
  },
  UnauthenticatedError: {
    id: 'errors.UnauthenticatedError',
    defaultMessage: "Vous n'êtes pas authentifié",
  },
  UnauthorizedError: {
    id: 'errors.UnauthorizedError',
    defaultMessage: "Vous n'êtes pas autorisé à réaliser cette action",
  },
  UnhandledError: { id: 'errors.UnhandledError', defaultMessage: 'Une erreur est survenue' },
  UserHasNoNewEmailError: {
    id: 'errors.UserHasNoNewEmailError',
    defaultMessage: "L'utilisateur n'a pas entamé la mise à jour de son adresse email",
  },
  ValidationError: {
    id: 'errors.ValidationError',
    defaultMessage: 'Une erreur de validation est survenue',
  },
  ValidationError_phonenumber: {
    id: 'errors.ValidationError_phonenumber',
    defaultMessage: 'Le numéro de téléphone est invalide',
  },
});
