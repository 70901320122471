import { combineReducers } from 'redux';
import { reducer as formReducer } from './form';
import { reducer as notificationsReducer } from './notifications';
import { reducer as routingReducer } from './routing';
import { reducer as sessionReducer } from './session';

export default combineReducers({
  routing: routingReducer,
  form: formReducer,
  notifications: notificationsReducer,
  session: sessionReducer,
});
