import {
  addNotifications,
  clearNotifications,
  getCurrentNotifications,
  hasNotificationsQueued,
  removeNotification,
  showNextNotifications,
} from './ducks';

export { default as reducer, rootSaga as saga } from './ducks';
export type { INotification } from './ducks';
export const actions = {
  addNotifications,
  removeNotification,
  clearNotifications,
  showNextNotifications,
};
export const selectors = { hasNotificationsQueued, getCurrentNotifications };
