import { createWrapper, MakeStore } from 'next-redux-wrapper';
import { AnyAction, applyMiddleware, createStore, Middleware, Store } from 'redux';
import createSagaMiddleware, { Task } from 'redux-saga';
import rootReducer from './reducer';
import rootSaga from './saga';
import { IState } from './types';

export interface SagaStore extends Store {
  sagaTask?: Task;
}

const bindMiddleware = (middleware: Middleware[]) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const getAppStateFromLocalStorage = () => {
  try {
    const serialState = localStorage.getItem('appState-1');
    if (serialState === null) {
      return undefined;
    }
    return JSON.parse(serialState);
  } catch (err) {
    return undefined;
  }
};

export const makeStore: MakeStore<Store<IState, AnyAction>> = () => {
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    rootReducer,
    getAppStateFromLocalStorage(),
    bindMiddleware([sagaMiddleware])
  );

  (store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export const wrapper = createWrapper(makeStore, { debug: false });
