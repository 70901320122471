import { useEffect, useState } from "react";
import Script from "next/script"
import { useViewerQuery } from "generated/graphql";
import { Viewer } from "types";

export const Intercom = () => {
  const [userHash, setUserHash] = useState('');
  const { data } = useViewerQuery();

  const viewer = data?.viewer;

  useEffect(() => {
    const identifyUser = async (user: Viewer) => {
      try {
        fetch('/api/intercom', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
          body: JSON.stringify({ id: user.id }),
        }).then(response => response.json()).then(data => { setUserHash(data.userHash) });

      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };

    if (viewer) {
      identifyUser(viewer);
    }
  }, [viewer]);

  return (
    <Script id={`intercom-${userHash}`} key={`intercom-${userHash}`} strategy="lazyOnload">
      {`
        var APP_ID = "${process.env.NEXT_PUBLIC_INTERCOM_APP_ID}";

        window.intercomSettings = {
          app_id: APP_ID,
          api_base: "https://api-iam.eu.intercom.io",
          ${userHash ? `name: ${viewer?.profile.firstName ? `"${viewer?.profile.firstName} ${viewer?.profile.lastName}"` : undefined},
              email: ${viewer?.profile.email ? `"${viewer?.profile.email}"` : undefined},
              user_id: ${viewer?.id ? `"${viewer?.id}"` : undefined},
              user_hash: "${userHash}"`
          : ''}
        };

      (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload', l);}else{w.addEventListener('load', l, false);}}})();`}
    </Script>
  )
}