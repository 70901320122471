import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction } from 'redux';
import { select, takeEvery } from 'redux-saga/effects';
import { IState } from '../types';

export const actionTypes = {
  UPDATE_FORM: 'UPDATE_FORM',
  CLEAR_FORM: 'CLEAR_FORM',
};

const initialState: IState = {};

/* Action creators */

export const updateForm: (formId: string, values: Record<string, any>) => AnyAction = (
  formId,
  values
) => ({
  type: actionTypes.UPDATE_FORM,
  formId,
  values,
});

export const clearForm: (formId: string) => AnyAction = (formId) => ({
  type: actionTypes.CLEAR_FORM,
  formId,
});

/* Selectors */

export const getForm: (formId: string) => (state: IState) => Record<string, any> | undefined =
  (formId) => (state) =>
    state.form[formId];

/* Reducer */

const reducer: (state: IState, action: AnyAction) => IState = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload.form };
    }

    case actionTypes.UPDATE_FORM: {
      const { formId, values: newValues } = action;
      const currentValues = state[formId] || {};
      const updatedForm = { ...currentValues, ...newValues };
      return { ...state, [formId]: updatedForm };
    }

    case actionTypes.CLEAR_FORM: {
      const { formId } = action;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [formId]: _, ...newState } = state;
      return newState;
    }

    default:
      return state;
  }
};

export function* localStateModifier(): Generator<any, any, any> {
  const { form } = yield select();

  try {
    const serialFormState = JSON.stringify({ form });
    localStorage.setItem('appState-1', serialFormState);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}

export function* rootSaga(): Generator<any, any, any> {
  yield takeEvery(actionTypes.UPDATE_FORM, localStateModifier);
  yield takeEvery(actionTypes.CLEAR_FORM, localStateModifier);
}

export default reducer;
