import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { getIntlProps, withIntlApp } from '@moxy/next-intl';
import atob from 'atob';
import btoa from 'btoa';
import es6promise from 'es6-promise';
import { NextPage } from 'next';
import NextApp, { AppProps } from 'next/app';
import ErrorBoundary from 'components/ErrorBoundary';
import { Intercom } from 'components/Intercom';
import RouteChangeHandler from 'components/RouteChangeHandler';
import { useApollo } from 'lib/apolloClient';
import polyfill from 'lib/intl/polyfills';
import { wrapper } from 'lib/redux/store';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../styles/date-range-picker.css';
import '../styles/index.css';
import '../styles/mapbox.css';
import '../styles/multi-range-slider.css';
import 'react-international-phone/style.css';

es6promise.polyfill();

global.atob = atob;
global.btoa = btoa;

const App: NextPage<AppProps<{ initialApolloState: NormalizedCacheObject | null }>> = ({
  Component,
  ...rest
}) => {
  const { store, props } = wrapper.useWrappedStore(rest);
  const client = useApollo(props.pageProps.initialApolloState);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GTM_ID)
      TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID });
  }, []);

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <RouteChangeHandler>
            <Component {...props.pageProps} />
            <Intercom />
          </RouteChangeHandler>
        </ApolloProvider>
      </Provider>
    </ErrorBoundary>
  );
};

const getInitialProps: typeof NextApp.getInitialProps = async (appContext) => {
  const { ctx } = appContext;

  const intlProps = await getIntlProps(ctx.locale);

  const appProps = await NextApp.getInitialProps(appContext);

  return {
    ...intlProps,
    ...appProps,
  };
};

const loadLocale = async (locale: string) => {
  if (locale) {
    await polyfill(locale);
    return import(/* webpackChunkName: "intl-messages" */ `../../intl/${locale}.json`).then(
      (mod) => mod.default
    );
  }

  return {};
};

App.getInitialProps = getInitialProps as any;

export default withIntlApp(loadLocale)(App);
