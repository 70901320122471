import React, { ErrorInfo } from 'react';
import * as Sentry from '@sentry/nextjs';

class ErrorBoundary extends React.Component<{ children: React.ReactNode }> {
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.setExtras({ '💥 boundary': errorInfo.componentStack });
    Sentry.captureException(error);
  }
  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
